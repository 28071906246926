import i18n from '@/i18n'

const SupplierMain = () =>
  import(
    /* webpackChunkName: "supplier-main" */ '@/views/supplier/newGrid/SupplierMain'
  )
const SupplierMainLayout = () =>
  import(
    /* webpackChunkName: "supplier-main-overview" */ '@/views/supplier/newGrid/SupplierMainLayout'
  )
const SupplierDashboard = () =>
  import(
    /* webpackChunkName: "supplier-main-overview" */ '@/views/supplier/newGrid/SupplierDashboard'
  )
const SupplierTask = () =>
  import(
    /* webpackChunkName: "supplier-task" */ '@/views/supplier/newGrid/task/SupplierTask'
  )
const RevisionMain = () =>
  import(
    /* webpackChunkName: "supplier-revision" */ '@/views/supplier/newGrid/revision/RevisionMain'
  )
const SupplierPaymentMethods = () =>
  import(
    /* webpackChunkName: "supplier-payment-methods" */ '@/views/supplier/newGrid/settings/SupplierPaymentMethods'
  )
const SupplierPaymentHistory = () =>
  import(
    /* webpackChunkName: "supplier-payment-history" */ '@/views/supplier/newGrid/settings/SupplierPaymentHistory'
  )
const SupplierProfile = () =>
  import(
    /* webpackChunkName: "supplier-profile" */ '@/views/supplier/newGrid/settings/SupplierProfile'
  )
const SupplierAvailability = () =>
  import(
    /* webpackChunkName: "supplier-availability" */ '@/views/supplier/newGrid/SupplierAvailability'
  )
const News = () =>
  import(/* webpackChunkName: "supplier-news" */ '@/views/shared/News')
const SupplierClients = () =>
  import(
    /* webpackChunkName: "supplier-clients" */ '@/views/supplier/newGrid/SupplierClients'
  )
const SupplierServices = () =>
  import(
    /* webpackChunkName: "supplier-services" */ '@/views/supplier/newGrid/settings/SupplierServices'
  )
const SupplierClientServices = () =>
  import(
    /* webpackChunkName: "supplier-clients-service" */ '@/views/supplier/newGrid/SupplierClientServices'
  )
const SupplierGDPR = () =>
  import(
    /* webpackChunkName: "supplier-g-d-p-r" */ '@/views/supplier/newGrid/settings/SupplierGDPR'
  )
const Mfa = () =>
  import(/* webpackChunkName: "supplier-m-f-a" */ '@/views/shared/Mfa')
const SupplierChat = () =>
  import(/* webpackChunkName: "chat" */ '@/views/supplier/chat/SupplierChat')
const SupplierCooperationAgreement = () =>
  import(
    /* webpackChunkName: "supplier-cooperation-agreement" */ '@/views/supplier/newGrid/settings/SupplierCooperationAgreement'
  )

//Copy editing
const SupplierCopywriting = () =>
  import(
    /* webpackChunkName: "supplier-copywriting" */ '@/views/supplier/newGrid/SupplierCopywriting'
  )

export default [
  {
    path: 'supplier',
    name: 'supplierMain',
    component: SupplierMain,
    children: [
      {
        path: '',
        name: 'supplierMainLayout',
        component: SupplierMainLayout,
        meta: {
          userType: ['supplier', 'admin']
        },
        children: [
          {
            path: 'chat',
            name: 'supplierChat',
            component: SupplierChat,
            meta: {
              baseRoute: 'supplierChat',
              userType: ['supplier', 'admin'],
              breadcrumb: [{ name: i18n.t('shared.label.chat'), linkTo: null }],
              icon: require('@/assets/icons/navbar/default/chat.svg')
            }
          },
          {
            path: 'copy-editing/:id',
            name: 'supplierCopywriting',
            component: SupplierCopywriting,
            meta: {
              baseRoute: 'supplierCopywriting',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                { name: i18n.t('shared.copywriting.title'), linkTo: null }
              ],
              icon: require('@/assets/icons/navbar/default/overview.svg')
            }
          },
          {
            path: 'content-pillar/:id',
            name: 'supplierContentPillar',
            component: SupplierCopywriting,
            meta: {
              baseRoute: 'supplierContentPillar',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('shared.content_pillar_editor.title'),
                  linkTo: null
                }
              ],
              icon: require('@/assets/icons/navbar/default/overview.svg')
            }
          },
          {
            path: 'dashboard',
            name: 'supplierDashboard',
            component: SupplierDashboard,
            meta: {
              baseRoute: 'supplierDashboard',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                { name: i18n.t('supplier.nav.tasks.btn'), linkTo: null }
              ],
              icon: require('@/assets/icons/navbar/default/overview.svg')
            }
          },
          {
            path: 'availability',
            name: 'supplierAvailability',
            component: SupplierAvailability,
            meta: {
              baseRoute: 'supplierAvailability',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                { name: i18n.t('supplier.nav.availability.btn'), linkTo: null }
              ],
              icon: require('@/assets/icons/navbar/default/my-availability.svg')
            }
          },
          {
            path: 'clients',
            name: 'supplierClients',
            component: SupplierClients,
            meta: {
              baseRoute: 'supplierClients',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                { name: i18n.t('supplier.nav.clients.btn'), linkTo: null }
              ],
              icon: require('@/assets/icons/navbar/default/my-customers.svg')
            }
          },
          {
            path: 'clients/:clientId',
            name: 'supplierClientServices',
            component: SupplierClientServices,
            meta: {
              baseRoute: 'supplierClients',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('supplier.nav.clients.btn'),
                  linkTo: 'supplierClients'
                }
              ],
              icon: require('@/assets/icons/navbar/default/my-customers.svg')
            }
          },
          {
            path: 'task/:id',
            name: 'supplierTask',
            component: SupplierTask,
            meta: {
              baseRoute: 'supplierDashboard',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('supplier.nav.tasks.btn'),
                  linkTo: 'supplierDashboard'
                }
              ],
              icon: require('@/assets/icons/navbar/default/overview.svg')
            }
          },
          {
            path: 'task/:id/revision',
            name: 'supplierRevision',
            component: RevisionMain,
            meta: {
              baseRoute: 'supplierDashboard',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                {
                  name: i18n.t('supplier.nav.tasks.btn'),
                  linkTo: 'supplierDashboard'
                }
              ],
              icon: require('@/assets/icons/navbar/default/overview.svg')
            }
          },
          {
            path: 'settings/payment/methods',
            name: 'supplierSettings',
            component: SupplierPaymentMethods,
            meta: {
              baseRoute: 'supplierSettings',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                { name: i18n.t('shared.nav.settings.btn') },
                { name: i18n.t('shared.nav.payment.btn') },
                { name: i18n.t('supplier.sub_nav.methods.btn'), linkTo: null }
              ],
              icon: require('@/assets/icons/navbar/default/settings.svg')
            }
          },
          {
            path: 'settings/payment/history',
            name: 'supplierPaymentHistory',
            component: SupplierPaymentHistory,
            meta: {
              userType: ['supplier', 'admin'],
              baseRoute: 'supplierSettings',
              breadcrumb: [
                { name: i18n.t('shared.nav.settings.btn') },
                { name: i18n.t('shared.nav.payment.btn') },
                { name: i18n.t('supplier.sub_nav.history.btn'), linkTo: null }
              ],
              icon: require('@/assets/icons/navbar/default/settings.svg')
            }
          },
          {
            path: 'settings/account/profile',
            name: 'supplierProfile',
            component: SupplierProfile,
            meta: {
              baseRoute: 'supplierSettings',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                { name: i18n.t('shared.nav.settings.btn') },
                { name: i18n.t('shared.nav.account.btn') },
                { name: i18n.t('shared.sub_nav.profile.btn'), linkTo: null }
              ],
              icon: require('@/assets/icons/navbar/default/settings.svg')
            }
          },
          {
            path: 'settings/account/services',
            name: 'supplierServices',
            component: SupplierServices,
            meta: {
              baseRoute: 'supplierSettings',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                { name: i18n.t('shared.nav.settings.btn') },
                { name: i18n.t('shared.nav.account.btn') },
                { name: i18n.t('supplier.sub_nav.services.btn'), linkTo: null }
              ],
              icon: require('@/assets/icons/navbar/default/settings.svg')
            }
          },
          {
            path: 'settings/account/cooperation-agreement',
            name: 'supplierCooperationAgreement',
            component: SupplierCooperationAgreement,
            meta: {
              baseRoute: 'supplierSettings',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                { name: i18n.t('shared.nav.settings.btn') },
                { name: i18n.t('shared.nav.account.btn') },
                {
                  name: i18n.t('supplier.sub_nav.cooperation.btn'),
                  linkTo: null
                }
              ],
              icon: require('@/assets/icons/navbar/default/settings.svg')
            }
          },
          {
            path: 'settings/account/gdpr',
            name: 'supplierGDPR',
            component: SupplierGDPR,
            meta: {
              baseRoute: 'supplierSettings',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                { name: i18n.t('shared.nav.settings.btn') },
                { name: i18n.t('shared.nav.account.btn') },
                { name: 'GDPR', linkTo: null }
              ],
              icon: require('@/assets/icons/navbar/default/settings.svg')
            }
          },
          {
            path: 'settings/account/mfa',
            name: 'supplierMFA',
            component: Mfa,
            meta: {
              baseRoute: 'supplierSettings',
              userType: ['supplier', 'admin'],
              breadcrumb: [
                { name: i18n.t('shared.nav.settings.btn') },
                { name: i18n.t('shared.nav.account.btn') },
                { name: '2FA', linkTo: null }
              ],
              icon: require('@/assets/icons/navbar/default/settings.svg')
            }
          }
        ]
      },
      {
        path: 'supplier/news',
        name: 'supplierNews',
        component: News,
        meta: {
          baseRoute: 'supplierNews',
          userType: ['supplier', 'admin'],
          breadcrumb: [{ name: i18n.t('shared.nav.news.title'), linkTo: null }],
          icon: require('@/assets/icons/navbar/default/news.svg')
        }
      }
    ]
  }
]
